
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";
import QRCode from "qrcode";

import CSpinner from "@/components/spinner.vue";
import CFormSelect from "@/components/form/select.vue";
import CCreditCardCard from "@/views/credit-card/card.vue";
import IPix from "@/assets/icons/pix.vue";

import { compareDates } from "@/utils";
import { useOpenFile } from "@/utils/mixins";
import { addMoneyMask, formatDateMMAAAA, formatDateTimeEn, formatDate, formatDateTime } from "@/utils/formatters";
import { CreditCardForm } from "@/store/credit-card/types";

const VPayment = defineComponent({
  name: "VPayment",
  components: { CCreditCardCard, CFormSelect, CSpinner, IPix },
  setup() {
    useHead({ title: "Efetuar pagamento | obmed" });
    const BOLETO_DATE_LIMITE = 3;

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { openPDF } = useOpenFile();
    const _id = Number(route.params._id);
    const interval = ref<any>(null);

    store.commit("setCartTransaction", null);

    const payment = computed(() => store.state.cart.list);
    const filteredCart = computed(() => payment.value?.carrinho.filter((item) => item.cd_paciente.id === _id));

    const paymentMethod = reactive<{ deadline: string | null; selected: "pix" | "credit_card" | "boleto" }>({
      deadline: null,
      selected: "credit_card",
    });
    const boletoDateLimite = new Date(new Date().setDate(new Date().getDate() + BOLETO_DATE_LIMITE * 2)).toISOString();

    const creditCard = computed({
      get() {
        return store.state.creditCard.form;
      },
      set(value: CreditCardForm | null) {
        store.commit("setCreditCardForm", value);
      },
    });
    const pix = computed(() => store.state.cart.pix);
    const boleto = computed(() => paymentMethod.selected === "boleto");
    const transaction = computed({
      get() {
        return store.state.cart.transaction;
      },
      set(value: any) {
        store.commit("setCartTransaction", value);
      },
    });
    const copyButton = ref<HTMLButtonElement | null>(null);

    const boletoIsPossible = computed(
      () =>
        !itemPayment.value?.find(
          (item) =>
            !compareDates(
              item.dt_agenda,
              new Date(new Date().setDate(new Date().getDate() + BOLETO_DATE_LIMITE * 2)).toISOString().split("T")[0]
            )
        )
    );

    const itemPayment = computed(() => getItems(route.query.ci?.toString().split(",") || []));
    const loading = reactive({ payment: false, pay: false });
    const price = computed(() => itemPayment.value?.reduce((price, item) => item.nr_valor + price, 0));
    const parcels = ref(1);
    const parcelOptions = computed(() =>
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((parcel) => ({
        label: `${parcel}x de ${addMoneyMask(Number(price.value) / parcel)}`,
        value: parcel,
      }))
    );

    function getItems(cartItems: string[]) {
      const items = payment.value?.carrinho.filter((item) => cartItems.includes(item.id?.toString()));
      return items?.length ? items : payment.value?.carrinho || [];
    }

    function createQRCode() {
      QRCode.toCanvas(document.querySelector("#payment-method-pix"), String(pix.value));
    }

    function handleSelectBoletoMethod() {
      paymentMethod.selected = "boleto";
    }

    function handleSelectPixMethod() {
      paymentMethod.selected = "pix";
    }

    function handlePrint() {
      openPDF({ url: transaction.value?.boleto_url });
    }

    function handleCopyPixCode() {
      navigator.clipboard.writeText(String(pix.value));
      if (!copyButton.value) return;

      copyButton.value.textContent = "Copiado!";
      copyButton.value.classList.add("copied");

      setTimeout(() => {
        if (!copyButton.value) return;

        copyButton.value.textContent = "Copiar";
        copyButton.value.classList.remove("copied");
      }, 5000);
    }

    async function handlePayment() {
      loading.pay = true;
      const response = await store.dispatch("payCart", {
        form: {
          nr_valor_total: price.value || 0,
          cd_paciente: _id,
          parcelas: Number(parcels.value) || 1,
          items:
            itemPayment.value?.map((item) => ({
              id: String(item.id),
              quantity: 1,
              tangible: false,
              unit_price: item.nr_valor,
              title: item.cd_especialidade?.id ? "Consulta" : "Exame",
            })) || [],
          forma_pagamento: creditCard.value ? "credit_card" : paymentMethod.selected,
          card_holder_name: creditCard.value?.card_holder_name,
          card_expiration_date: creditCard.value?.card_expiration_date,
          card_number: creditCard.value?.card_number,
          card_cvv: creditCard.value?.card_cvv,
          boleto_expiration_date: boleto.value
            ? formatDateTimeEn(
                new Date(new Date().setDate(new Date().getDate() + BOLETO_DATE_LIMITE)).toISOString(),
                "23:59:00"
              )
            : undefined,
          boleto_instructions: boleto.value ? "" : undefined,
        },
      });
      loading.pay = false;

      if ((response?.status || 500) < 300 && response?.data?.status !== "error") {
        store.commit("addToast", { summary: "Agendamento realizado com sucesso!", severity: "success" });
        router.replace({ name: "cart" });
      } else if (response?.body?.pix_qr_code) {
        store.commit("setPix", response.body.pix_qr_code);
        paymentMethod.deadline = response.body.pix_expiration_date;
        createQRCode();
        consultPayment();
        interval.value = setInterval(() => consultPayment(), 5000);
      }
    }

    async function consultPayment() {
      const consultItems = itemPayment.value.map((item) => item.id);
      if (!consultItems.length) return;

      const response = await store.dispatch("consultPayment", consultItems);
      if (response?.data.pago) {
        store.commit("setToast", [{ summary: "Pagamento realizado com sucesso!", severity: "success" }]);
        router.replace({ name: "appointment" });
      }
    }

    async function getCartList() {
      loading.payment = true;
      await store.dispatch("getCartList");
      loading.payment = false;
    }

    getCartList();

    watch(
      () => paymentMethod.selected,
      () => {
        if (paymentMethod.selected !== "credit_card") creditCard.value = null;
        if (paymentMethod.selected !== "boleto") transaction.value = null;
        if (paymentMethod.selected === "pix" && !pix.value) {
          handlePayment();
        }
      }
    );

    watch(creditCard, () => {
      if (creditCard.value) paymentMethod.selected = "credit_card";
    });

    onMounted(() => {
      consultPayment();
      if (pix.value) {
        createQRCode();
        interval.value = setInterval(() => consultPayment(), 5000);
      }
    });

    onUnmounted(() => {
      clearInterval(interval.value);
    });

    return {
      ...{ payment, creditCard, filteredCart, transaction, itemPayment, pix, boletoDateLimite, paymentMethod },
      ...{ price, parcels, parcelOptions, loading, copyButton, boleto, boletoIsPossible, BOLETO_DATE_LIMITE },
      ...{ handlePayment, handlePrint, handleSelectPixMethod, handleSelectBoletoMethod, handleCopyPixCode },
      ...{ formatDateMMAAAA, addMoneyMask, formatDate, formatDateTime },
    };
  },
});

export default VPayment;
