
import { defineComponent } from "vue";

const CCreditCardCard = defineComponent({
  name: "CCreditCardCard",
  props: {
    creditCardID: { type: Number, default: 0 },
    creditCardBrand: { type: String, default: "unknown" },
  },
});

export default CCreditCardCard;
